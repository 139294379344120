<template>
    <div class="SpecialColumnManage">
        <select-course @submit="handleCourseSubmit"></select-course>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium" ref="table"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{padding: '5px 0'}" header-align="center" :row-key="(row) => row.id"
                :row-class-name='rowClassName'>
                <el-table-column label="单品名称" min-width="320" :show-overflow-tooltip="true">
                    <div slot-scope="scope" class="course-name">
                        <div class="course-cover">
                            <el-image :src="IMG_URL_PREFIX + scope.row.cover" v-if="scope.row.cover" fit='cover'
                                class="course-cover-img" />
                            <div v-else>
                                <i class="error iconfont icon-p-video"
                                    v-if="scope.row.media_type === COURSE.TYPE.VIDEO" />
                                <i class="error iconfont icon-p-image" v-else />
                            </div>
                        </div>
                        <div class="course-info" @click="routerChange(2, scope.row)">
                            <div class="name">{{scope.row.name}}</div>
                            <div class="price">￥{{scope.row.price | numFixed}}</div>
                        </div>
                    </div>
                </el-table-column>
                <el-table-column label="类型" min-width="100" align="center">
                    <span slot-scope="scope" class="text-colourful">
                        {{scope.row.media_type | courseTypeFilter}}
                    </span>
                </el-table-column>
                <el-table-column label="访问量" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.count || 0}}
                    </template>
                </el-table-column>
                <el-table-column label="状态" min-width="120" align="center">
                    <template slot-scope="scope">
                        <i class="iconfont icon-point" :class="scope.row.status | courseStatusFilter('class')" />
                        {{scope.row.status | courseStatusFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="155" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="course-sort icon-btn">
                            <el-tooltip placement="top" content="拖动排序">
                                <i class="iconfont icon-sort" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleCopy(scope.row.id)">
                            <el-tooltip placement="top" content="复制课程">
                                <i class="iconfont icon-copy" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange('edit',scope.row)">
                            <el-tooltip placement="top" content="编辑课程">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleStatusChange(scope.row)">
                            <el-tooltip placement="top" :content="scope.row.status === 2 ? '下架课程' : '上架课程'">
                                <i class="iconfont icon-sold-out" v-if="scope.row.status === 2" />
                                <i class="iconfont icon-putaway" v-else />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleRemoveCourse(scope.row)">
                            <el-tooltip placement="top" content="移出专栏">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
import Sortable from 'sortablejs';

const COURSE = Core.Const.COURSE;

export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
        SelectCourse: () => import('@/components/online-edu/SelectCourse')
    },
    props: {
        id: {}
    },
    data() {
        return {
            COURSE,
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL,
            tableData: []
        };
    },
    computed: {},
    mounted() {
        this.initSort();
        this.getTableData();
    },
    methods: {
        async getTableData() {
            await Core.Api.Course.columnList(this.id).then((res) => {
                this.tableData = res.list;
            });
            console.log('getTableData this.tableData:', this.tableData);
        },
        // 移出内容
        handleRemoveCourse(item) {
            this.$confirm('确定要将此课程移出这个专栏吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Course.removeItem(this.id, item.id).then((res) => {
                    this.$message.success('移出成功');
                    this.getTableData();
                });
            });
        },
        // 内容排序
        initSort() {
            const tbody = document.querySelector(
                '.el-table__body-wrapper tbody'
            );
            let ops1 = {
                handle: '.course-sort',
                dataIdAttr: 'class',
                onEnd: (evt) => {
                    var arr = sortable1.toArray();
                    this.handleSort(arr);
                }
            };
            var sortable1 = Sortable.create(tbody, ops1);
        },
        rowClassName({ row }) {
            return 'id:' + row.id;
        },
        handleSort(arr) {
            let ids = [];
            arr.forEach((item) => {
                let id = item.split('id:').pop();
                ids.push(id);
            });
            console.log('ids:', ids);
            Core.Api.Course.updateItemSeq(this.id, ids.join(',')).then(
                (res) => {
                    console.log('res:', res);
                    this.$message.success('课程顺序更改成功');
                    this.getTableData();
                }
            );
        },
        handleCopy(id) {
            Core.Api.Course.copyByItem(this.id, id).then(() => {
                this.$message.success('复制成功');
                this.getTableData();
            });
        },
        handleStatusChange(item) {
            this.$confirm(
                `确定要${item.status ? '下架' : '上架'}这个课程吗`,
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                Core.Api.Course.updateStatus(item.id)
                    .then((res) => {
                        this.$message.success(
                            `${item.status ? '下架' : '上架'}成功!`
                        );
                        this.getTableData();
                    })
                    .catch((err) => {
                        console.log('err:', err);
                        this.$message.error(
                            `${item.status ? '下架' : '上架'}成功!`
                        );
                        this.getTableData();
                    });
            });
        },
        // 新增内容
        handleCourseSubmit({ ids }) {
            console.log('handleCourseSubmit ids:', ids);
            Core.Api.Course.addItem(this.id, ids.join(',')).then(() => {
                this.$message.success('添加成功');
                this.getTableData();
            });
        },
        routerChange(type, item) {
            switch (type) {
                case 'edit':
                    this.$router.push({
                        path: '/online-course/course-edit',
                        query: {
                            type: item.media_type,
                            id: item.id
                        }
                    });
                    break;
                case 2:
                    console.log('item', item);
                    this.$router.push({
                        path: '/online-course/course-detail',
                        query: {
                            id: item.id
                        }
                    });
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.SpecialColumnManage {
    .table-container {
        margin-top: 15px;
        .course-name {
            display: flex;
            padding: 5px 13px;
            .course-cover {
                width: 80px;
                height: 60px;
                background: #dee5ea;
                border-radius: 2px;
                overflow: hidden;
                position: relative;
                .course-cover-img {
                    width: 100%;
                    height: 100%;
                }
                .error {
                    @include ct;
                    font-size: 20px;
                    opacity: 0.45;
                }
            }
            .course-info {
                @include flex(column, space-between, flex-start);
                font-size: 14px;
                margin-left: 10px;
                cursor: pointer;
                .name {
                    color: #363d42;
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .price {
                    color: #1D517E;
                    &::first-letter {
                        font-size: 10px;
                    }
                }
            }
        }
        .course-sort {
            cursor: move;
        }
    }
}
</style>